// src/stores/counter.js
import { defineStore } from 'pinia';

export const useCounterStore = defineStore('counter', {
  // 定义 state，类似于组件中的 data
  state: () => ({
    downloadId:'1864658594859978752',
    publicVapidKey : 'BGj4Yx4un16buPtFXFxBtXDAfN6WajQXoO6gK1SXkAuFoB8f2yR78XUtn3jN4-_wwBpC7lkEW-oTJBfdD5j3rxo',
    downloadUrl : '/prod-api',
    type:'facebook',
    pixelCode:'1201674067761049',
    model:'模板五',
    // appurl:'https://wuwad.xyz',
    appurl:'https://wuwad.xyz',
    urltype:'1',
  }),
});
